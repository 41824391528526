<template>
    <div>
        <v-data-table :headers="headers" :items="items">
            <!-- <template v-slot:[`item.actions`]="{ item }">
                <v-icon color="green" @click="editItem(item)"> mdi-pen </v-icon>
            </template>
            <template v-slot:[`item.resigned_date`]="{ item }">
                <span>{{item.resigned_date != null ? momentFnc(item.resigned_date): ''}}</span>
            </template>
            <template v-slot:[`item.updated_date`]="{ item }">
                <span>{{item.updated_date != null ? momentFnc(item.updated_date): ''}}</span>
            </template> -->
        </v-data-table>
      <v-btn @click="csv()">extract</v-btn>
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
    export default {
      data() {
        return {
            headers:[
                {text: "ConstructionCode", value: "ConstructionCode",align:'center'},
                {text: "HouseType", value: "HouseType",align:'center'},
                {text: "ConstructionTypeName", value: "ConstructionTypeName",align:'center'},
                {text: "EmployeeCode", value: "EmployeeCode",align:'center'},
                {text: "ContractDate", value: "contractDate",align:'center'},
                {text: "TempContractDate", value: "TempContractDate",align:'center'},
                {text: "SentProcessRequestDate", value: "SentProcessRequestDate",align:'center'},
                {text: "JoutouPlan", value: "JoutouPlan",align:'center'},
                {text: "DecidedLandDate", value: "DecidedLandDate",align:'center'},
                {text: "DecidedLandDays", value: "decidedlandDays",align:'center'},
                {text: "JoutouDays", value: "JoutouDays",align:'center'},
                {text: "KakouDays", value: "KakouDays",align:'center'},
                {text: "StopCheck", value: "stopcheck",align:'center'},
                {text: "FinalPlan", value: "finalPlan",align:'center'},
                {text: "CAD", value: "cad",align:'center'},
                {text: "CadFromStopCheck", value: "cadFromStop",align:'center'},
                {text: "CadFromTempContract", value: "cadFromTempContract",align:'center'},
                {text: "CadFromFP", value: "cadFromFP",align:'center'},
                {text: "CadFromFPwithHRDP", value: "cadFromFPwithHRDP",align:'center'},
                {text: "CadFromHRDP", value: "cadFromHRDP",align:'center'},
                {text: "CadPhase1", value: "CadPhase1",align:'center'},
                {text: "CadFinalPlan", value: "CadFinalPlan",align:'center'},
                {text: "CadPdr", value: "CadPdr",align:'center'},
                {text: "shiyou", value: "shiyou",align:'center'},
                {text: "Denki", value: "denki",align:'center'},
                {text: "denki017", value: "denki017",align:'center'},
                {text: "denkipdr", value: "denkipdr",align:'center'},
                {text: "ExternalPers", value: "ExternalPers",align:'center'},
                {text: "HP", value: "House_presentation",align:'center'},
                {text: "SI_WAKU_DUCT", value: "SI_WAKU_DUCT",align:'center'},
                {text: "SI_JIKU", value: "SI_JIKU",align:'center'},
                {text: "ALVS", value: "ALVS",align:'center'},
                ],
            items:[]
        }   
      },
      methods: {
            csv(){
                let csv = ""
                //header
                for(let header of this.headers){
                    csv += header.text + ","
                }
                csv+="\n"
                for(let item of  this.items){
                    for(let header of this.headers){
                        csv+= item[header.value] + ","
                    }
                    csv+="\n"
                }
                    csv+="\n"
                let filename = "BeforeKakouData.csv"
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, filename);
                } 
                else {
                    var link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        var url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", filename);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
                }},
            loadData(){
                axios.get(`http://10.169.140.17:4567/cad/newData`).then(res=>{
                    if(res.data){
                        this.items=res.data
                    }
                }).catch(err=>{
                    if(err.response.status == 403 || err.response.status == 401){
                        Swal.fire({
                        icon: "info",
                        title: `Token Expired System will forced logout`,
                        showConfirmButton: true,
                        }).then((result) => {
                        if (result.isConfirmed) {
                        this.$store.commit("STORE_USERINFO", null);
                        this.$store.commit("STORE_SALESOFFICE", null)
                        this.$store.commit("STORE_CHART_URL", null);
                        this.$router.push("/login");
                        }
                        });
                    }
                })
        },
        mounted () {
            this.loadData();
        },
    }
  </script>
  
  <style lang="scss" scoped>
  
  </style>